import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteConnectorConfigById,
  getAllConnectors
} from "../../services-dataIngestion/dataIngestion-combined-service";
import { addSnack } from "../../../../actions/snackbarActions";
import moment from "moment";
import AgGridComponent from "Utils/agGrid";
import { useRef } from "react";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import LoadingOverlay from "Utils/Loader/loader";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { DATAINGESTION_DATA_SOURCES_GET_ALL } from "modules/dataIngestion/constants-dataIngestion/apiConstants";
import axiosPlatformInstance from "Utils/axiosPlatform";
import { homePageActions } from "actions/homePageActions";
import { dataIngestionActions, updateSubModuleStatus } from "actions/dataIngestionActions";
import {
  DELETE,
  EDIT,
} from "modules/dataIngestion/constants-dataIngestion/moduleConstants";

const DataSourcesTable = (props) => {
  const [isLoading, setLoadingStatus] = useState(false);
  const [filterConfigsTableColumns, setfilterConfigsTableColumns] = useState(
    []
  );
  const [showDeleteConfirmModal, updateShowDeleteConfirmModal] = useState(
    false
  );
  const [currentConfigToDelete, updateCurrentConfigToDelete] = useState(null);
  const configTableRef = useRef(null);
  const [configTableData, setConfigTableData] = useState([]);


  const getConnectorConfig = async () => {
    try {
      setLoadingStatus(true);
      const data = await props.getAllConnectors()
      setLoadingStatus(false);
      props.updateSubModuleStatus(data?.data.length ? false : true)
      props.setTableData(data?.data);
      return data;
    } catch (e) {
      setLoadingStatus(false);
      if (e.response.status === 403) {
        displaySnackMessages(e.response.data.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const getColumns = (
    queryParams,
    levelsJson = {},
    actions = {},
    formatSetAllLabel = false
  ) => {
    const data = {
      total: null,
      page: null,
      count: null,
      status: true,
      data: [
        {
          sub_headers: [],
          tc_code: 1,
          label: "Name",
          column_name: "name",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: true,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 2,
          label: "Type",
          column_name: "type",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          isSearchable: true,
          extra: {},
          isMulti: false,
          is_sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 3,
          label: "Is valid",
          column_name: "is_valid",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 1,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 15,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 4,
          label: "Created by",
          column_name: "user_name",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 4,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 32,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 5,
          label: "Created on",
          column_name: "created_at",
          dimension: "Product",
          type: "str",
          is_frozen: false,
          is_editable: false,
          is_aggregated: false,
          order_of_display: 5,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 33,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: false,
          width: 200,
        },
        {
          sub_headers: [],
          tc_code: 39,
          label: "Edit",
          column_name: "edit",
          dimension: "User",
          type: "edit_icon",
          is_frozen: false,
          is_editable: props.userRole[EDIT] ? true : false,
          is_aggregated: false,
          order_of_display: 6,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 337,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: true,
          width: 100,
        },
        {
          sub_headers: [],
          tc_code: 39,
          label: "Delete",
          column_name: "delete",
          dimension: "User",
          type: "delete_icon",
          is_frozen: false,
          is_editable: props.userRole[DELETE] ? true : false,
          is_aggregated: false,
          order_of_display: 6,
          is_hidden: false,
          is_required: false,
          tc_mapping_code: 337,
          aggregate_type: "",
          formatter: "",
          is_row_span: false,
          footer: "",
          is_searchable: false,
          extra: {},
          is_sortable: true,
          width: 100,
        },
      ],
      message: "Successful",
      previous: null,
      next: null,
      offset: null,
    };
    return agGridColumnFormatter(
      data.data,
      levelsJson,
      actions,
      formatSetAllLabel
    );
  };

  useEffect(() => {
    let cols = getColumnsAccessDetail();
    setfilterConfigsTableColumns(cols);
  }, [props.userRole]);

  useEffect(() => {
    const fetchFilterConfigTableColumns = async () => {
      let data = await getConnectorConfig();
      setConfigTableData(Object.keys(data.data).length ? data.data : []);
      props.dataIngestionActions(Object.keys(data.data).length ? data.data : [])
    };
    fetchFilterConfigTableColumns();
  }, []);

  const getColumnsAccessDetail = () => {
    let cols = getColumns();
    if (!props.userRole[DELETE])
      cols = cols.filter((col) => col.column_name !== "delete");
    if (!props.userRole[EDIT])
      cols = cols.filter((col) => col.column_name !== "edit");
    cols = cols.map((col) => {
      if (col.column_name === "created_at") {
        col.accessor = "created_at";
        col.field = "created_at";
        col.cellRenderer = (params) => {
          return (
            <>{moment(params.data.created_at).format("MMM Do YY, hh:mm a")}</>
          );
        };
      }
      return col;
    });
    return cols;
  };

  const deleteConnectorConfig = async (data) => {
    try {
      setLoadingStatus(true);
      let payload = {
        name: data.data?.name,
        type: data.data?.type,
        value: {},
      };
      await props.deleteConnectorConfigById(
        data.data?.data_source_config_id,
        payload
      );
      setLoadingStatus(false);
      props.setRenderMappingAgGrid(false);
      props.addSnack({
        message: "Deleted connector successfully",
        options: {
          variant: "success",
        },
      });
      configTableRef.current.api.refreshServerSideStore({ purge: true });
    } catch (error) {
      setLoadingStatus(false);
      props.addSnack({
        message: "Failed to delete the connector",
        options: {
          variant: "error",
        },
      });
    }
  };

  const onEditHandler = (params) => {
    props.onEdit(params.data);
  };

  return (
    <>
      <LoadingOverlay loader={isLoading} spinner>
        <ConfirmPrompt
          showModal={showDeleteConfirmModal}
          title="Confirm Delete"
          message="Are you sure you want to delete the config ?"
          ariaLabeledBy="save-changes-dialog"
          primaryBtnText="Yes"
          secondaryBtnText="No"
          setConfirm={updateShowDeleteConfirmModal}
          confirmCallback={(val) => {
            if (val) {
              deleteConnectorConfig(currentConfigToDelete);
            }
            updateCurrentConfigToDelete(null);
          }}
        />
        {filterConfigsTableColumns.length > 0 && (
          <AgGridComponent
            columns={filterConfigsTableColumns}
            rowdata={configTableData}
            loadTableInstance={(gridInstance) => {
              configTableRef.current = gridInstance;
            }}
            sizeColumnsToFitFlag={true}
            callDeleteApi={(data) => {
              updateShowDeleteConfirmModal(true);
              updateCurrentConfigToDelete(data);
            }}
            onEditClick={(params) => onEditHandler(params)}
          />
        )}
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapActionsToProps = {
  addSnack,
  deleteConnectorConfigById,
  getAllConnectors,
  setUserApps: homePageActions,
  dataIngestionActions,
  updateSubModuleStatus
};
export default connect(mapStateToProps, mapActionsToProps)(DataSourcesTable);
