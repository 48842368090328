/**
 * BASE based on the production or development run
 */

export const BASE_API = `https://${window.location.host}/api/v2`;
export const BASE_API_TEST = `https://generic.devs.iaproducts.ai/api/v2`;
// export const BASE_API = "http://localhost:8080/api/v2";
export const BASE_API_V3 = "/api/v3";
export const TENANT = window.location.host.split(".")[0]
export const ENV = window.location.host.split(".")[1]
export const REDIRECT_URL = `https://productivityhelper.dev.iaproducts.ai/productivityHelper`;

/**
 * Platform API goes belows
 */
// websocketURl
// export const WEBSOCKET_URL = `wss://${window.location.host}/api/v2/notifications/connect?channels=channel:1,channel:2&`;
export const WEBSOCKET_URL = `wss://${window.location.host}/etlws`;
export const WEBSOCKET_NOTIFICATION_URL = `wss://${window.location.host}/notifications`;
export const WEBSOCKET_DATA_INGESTION_URL = `wss://${window.location.host}/sample-ingestion/ws`;

//please create api based on structure of below attribute api
export const FETCH_TENANT = "/data-platform/google-tenant-id";
export const VERIFY_TOKEN = "/user/verify-token";
export const USER_ROLES = "/user/userrole";
export const USER_DETAILS = "/user/userdetail";
export const USER_PERMISSIONS = "/user/permissions";
export const USER_GROUPS = "/user/groups";
export const USER_ROLE_GROUP_MAP = "/user/map_user_role_group";

export const FILTER_ELEMENTS = "/common/filter_elements";
export const FILTER_PRODUCT_DETAILS = "/product/product_details";

export const POSTHOG_API = "https://posthog-devs.impactsmartsuite.com";

/**
 * Attribute Smart API goes below
 */

export const AS_BASE = "/attribute-smart";

//Dashboard API
export const AS_DASHBOARD_UPLOAD = AS_BASE + "/upload/";
export const AS_DASHBOARD_EXPORT = AS_BASE + "/export_product_attributes/";
export const AS_DASHBOARD_ERROR_REPORT = AS_BASE + "/export-image-error/";

//Explore Summary API
export const AS_EXPLORE_SUMMARY = AS_BASE + "/explore-api/";

//Explore category API
export const AS_EXPLORE_CATEGORY = AS_BASE + "/explore-category-api/";
export const AS_EXPLORE_VARIANT_CATEGORY =
  AS_BASE + "/explore-variant-category/";
export const AS_EXPLORE_FILTER = AS_BASE + "/explore-category-filter-api/";
export const AS_EXPLORE_VARIANT_FILTER =
  AS_BASE + "/explore-variant-category-filter/";
export const AS_SINGLE_EDIT_SAVE = AS_BASE + "/product_attributes/";
export const AS_FETCH_ATTRIBUTES = AS_BASE + "/fetch_tenant_attributes/";

//Product Detail Page
export const AS_PRODUCT_DETAIL = AS_BASE + "/product-view/";

//Workflow Page API
export const TRANSITION = "/workflow/transitionmeta/";
export const WORKFLOW = "/workflow/workflow/";
export const STATES = "/workflow/state/";
export const TRANSITIONAPPROVAL = "/workflow/transitionapproval/";

// Ticketing API
export const TICKETING = "/ticket/";
export const UPDATE_STATUS = "/ticket/update_status/";
export const TICKETING_SUPTYPE = "/ticket/subtype/";
export const TICKETING_TYPE = "/ticket/type/";

// Column API
export const GET_COLUMNS = "/core/table-fields";
export const SET_ALL_DATA = "/common/post_all_data";

//Filter API
export const MODEL_FIELDS = "/common/model_fields_properties";
export const SET_FILTER_ELEMENTS = "/common/filter_elements";
export const CREATE_DIMENSION_FILTER = "/master/dimension-table/filters/";
export const SAVE_FILTER_SELECTION = "/core/filter/user-preference";

//Product Filter
export const GET_ALL_FILTER = "/core/filter-configuration/screen";
export const GET_FILTER_OPTIONS = "/core/attribute-filter";
export const GET_COMBINED_FILTER_OPTIONS = "/core/attribute-filter-v2";
export const GET_TABLE_DATA = "/master/dimension-table";
export const GET_MASTER_DIMENSION_DATA = "/master/dimension-table/basic";
export const GET_TABLE_DATA_AGGREGATE = "/master/dimension-table/aggregate";
export const VALIDATE_MAPPING = "/master/map-style";
export const VALID_PRODUCTS_STYLE = "master/map-style";
export const STYLE_PRODUCT_MAPPING = "/master/dimension-table/bulk/product";

export const SET_ALL_TABLE_DATA = "/master/dimension-table/set-all";
export const TIME_ATTRIBUTE = "/master/dimension-table/time-attribute";
export const GET_PRODUCT_GROUPS = "/core/group/product";

export const MAP_STORE_TO_PRODUCT = "/store-mapping/store-to-product";
export const GET_STORE_TO_DC = "/store-mapping/store-dc";
export const GET_STORE_TO_FC = "/store-mapping/store-fc";
export const GET_ALL_FC = "/master/fc";
export const GET_ALL_DC = "/master/dc";

export const MAP_STORE_TO_FC = "/store-mapping/map-store-to-fc";
export const MAP_STORE_TO_DC = "/store-mapping/map-store-to-dc/inline-save";
export const GET_REF_STORES = "/store-mapping/ref-stores";
export const GET_STORES_Product = "/store-mapping/products";
export const GET_STORES_GROUPS_Product = "/store-group-mapping/products";
export const POST_STORES_GROUP_TO_PRODUCT =
  "/store-group-mapping/store-group-to-product";
export const POST_STORES_TO_PRODUCT =
  "/store-mapping/store-to-product/inline-save";
export const UNMAPPED_STORE_PRODUCT = "/store-mapping/unmapped-stores";
export const UNMAPPED_STORE_GROUP_PRODUCT =
  "/store-group-mapping/unmapped-stores";
export const GET_MAPPED_PRODUCT_STORE = "/store-mapping/products/";
export const GET_MAPPED_PRODUCT_STORE_GROUP = "/store-group-mapping/products/";

// Inventory
export const DC_LIST = "/master/dc-list";
export const INVENTORY_OVERALL_VIEW = "/master/overall-view";
export const INVENTORY_DC_VIEW = "/master/dc-view";
export const INVENTORY_DC_STORE_VIEW = "/master/dc-store-view";
export const INVENTORY_STORE_VIEW = "/master/store-view";
export const INVENTORY_PRODUCT_VIEW = "/master/product-view";
export const GET_PRODUCT_TO_DC = "/product-mapping/product-dc";
export const GET_PRODUCT_TO_FC = "/product-mapping/product-fc";
export const MAP_PRODUCT_TO_DC = "/product-mapping/map-product-dc";
export const MAP_PRODUCT_TO_FC = "/product-mapping/map-product-fc";

export const TENANT_APP_CONFIG = "core/tenant/app_conf/assort";
export const HIERARCHIES_LIST = "core/tenant/hierarchies_list/";
export const GET_APP_CONFIG = "core/tenant/app_conf/";
export const GET_KPI_CONFIG = "core/tenant/assort/kpi";
export const GET_EXITING_PLANNING_LEVEL = "/core/tenant/assort/planning_lvl";

//event configuration
export const GET_EVENTS = "/notifications/event";
export const GET_EVENT_TYPE = "/notifications/event/types-triggers";
export const GET_APP_SCREENS = "/notifications/event/application-screens";
export const GET_APP_SCREENS_ACTIONS = "/notifications/event/screen-actions";
export const GET_DEPARTMENTS_CHANNEL = "/notifications/event/filter";

//user management
export const GET_USER_SCREENS = "/master/user-management/screens";
export const GET_MODULE_LEVEL_ACCESS =
  "/master/user-management/module-hierarchy";
// TENANT MANAGEMENT - MANAGE USER ROLE & MANAGE USER
export const GET_ROLE_MGMT_FILTER_CONFIG = "/core/user-role-mgmt/filter-conf";
export const GET_ROLE_MGMT_TABLE_CONFIG = "/core/user-role-mgmt/table-conf";
export const GET_ROLE_MGMT_FILTER_ATTRIBUTES =
  "/core/user-role-mgmt/attributes";
export const FETCH_TABLE_BASED_ON_FILTER = "/core/user-role-mgmt/mappings";
export const TABLE_HIERARCHY_ASSIGN_ROLE = "/core/user-role-mgmt/hierarchy";
export const GET_FILTERED_ATTRS_TO_ASSING_ROLE =
  "/core/user-role-mgmt/hierarchy-attributes";
export const GET_URM_FILTERS_LIST = "/core/user-role-mgmt/urm-filters";
export const FETCH_LIST_OF_USER_MODULE_ROLE = "/data-platform/user-mgmt/list-user-module-role";
export const SAVE_USER_ROLE_MODULE_MAPPING = "/data-platform/user-mgmt/create-user-module-role";
export const DELETE_USER_ROLE_MODULE_MAPPING = "/data-platform/user-mgmt/remove-user-module-role";

// same end point for all requests, maintaining one
export const SAVE_UPDATE_DELETE_USER_ROLE_MAPPING = "/core/user-role-mgmt/role";
export const ASSIGN_USER_ROLE_FORM_FILTERS =
  "core/user-role-mgmt/role-attributes";
export const ASSIGN_USER_ROLE_GET_MODULE_LIST =
  "/data-platform/user-mgmt/get-modules";

// Manage User
export const SAVE_NEW_USER = "/core/user-mgmt/create";
export const DELETE_EXISTING_USER = "/core/user-mgmt/remove";
export const GET_USERS = "/core/user-mgmt/list";
export const GET_HOME_APPLICATION = "/core/user-role-mgmt/applications";
export const GET_SCREEN_LEVEL_ACCESS =
  "master/user-management/screen-hierarchy";
export const GET_USER_DETAILS = "/core/user-mgmt/get-user-details";

// tenant Config
export const GET_TENANT_CONFIG = "core/tenant-config";
export const GET_TENANT_FILTER_CONFIG = "/core/tenant-filter/filter-config";
export const POST_TENANT_FILTER_CONFIG =
  "/core/tenant-filter/create-filter-config";
export const GET_APPLICATION_MASTER = "/core/applications";
export const UPDATE_TENANT_ATTRIBUTE_CONFIG =
  "/core/tenant-config/create-config";
export const FETCH_UNASSIGNED_ROLES_LIST = "/core/user-mgmt/list/no-roles";
export const FETCH_CLIENT_SUBSCRIBED_APPS = "/core/tenant-mgmt/applications";
export const FETCH_CONFIGURATOR_APP_MODULES_INFO = "core/configuration/modules";

  export const PUT_DATA_INGESTION_SOURCING_CONFIG =
  "/data-platform/configuration/sourcing_configuration";
  export const PUT_DATA_INGESTION_CONFIG =
  "/data-platform/configuration/ingestion_configuration";
  export const PUT_MAPPING_TABLE_CONFIG =
  "/data-platform/source_mapping";
  export const PUT_DERIVED_TABLE_CONFIG =
  "/data-platform/derived_tables_mapping";
  export const PUT_TRIGGERS_TABLE_CONFIG =
  "/data-platform/generic_trigger_mapping";

  export const PUT_QC_KPI_MODULE_TABLE_CONFIG = 
  "data-platform/qc_module/kpi_master";
  export const PUT_QC_RULE_MODULE_TABLE_CONFIG = 
  "data-platform/qc_module/rule_master";
  export const QC_ADD_TABLE_CONFIG = 
  "/data-platform/qc_module/custom_qc/add-table-entry"
  export const QC_ADD_CUSTOM_QUERY_MODULE_TABLE_CONFIG = 
  "data-platform/qc_module/custom_qc/add-qc"
  export const PUT_QC_CUSTOM_QUERY_UPDATE_TABLE_CONFIG = 
  "data-platform/qc_module/custom_qc/update-qc"
  export const REMOVE_QC_CUSTOM_QUERY_TABLE_CONFIG = 
  "data-platform/qc_module/custom_qc/remove-qc"

//Store Cluster Grouping
export const FETCH_STORE_GROUP_CLUSTER_METRIC_PARAMETERS =
  "/core/group/store/cluster";
export const SUBMIT_CUSTOM_STORE_GROUP_CLUSTER_REQUEST =
  "/core/group/store/cluster";
export const FETCH_STORE_GROUP_CUSTOM_CLUSTER_INFO =
  "/core/group/store/cluster/job";

//Get release notes
export const GET_RELEASE_NOTES = "/core/release-notes";

// Comment
export const GET_COMMENT = "/core/get-comment";
export const CREATE_COMMENT = "/core/comment";

//
export const GET_SCREEN_MASTER = "/core/user-role-mgmt/screens";

//cross-dimensional-api
export const CROSS_DIMENSIONAL_API = "/core/cross-filter";
export const COMBINED_CROSS_DIMENSIONAL_API = "/core/cross-filter-v2";

//Product Mapping
export const MAP_PRODUCTS_TO_STORE_GROUP =
  "/product-mapping/product-to-store-group";

// Fiscal Calendar Api
export const GET_FISCAL_CALENDAR = "/core/timeline/fiscal-calendar-data";


// Productivity Helper API
export const GET_PROJECTS = "/productivity_helper/get_projects";
export const GET_DATASET = "/productivity_helper/get_datasets";
export const GET_TABLES = "/productivity_helper/get_tables";
export const GET_TABLE_DESCRIPTION = "/productivity_helper/get_table_description";
export const GET_SCHEMA = "/productivity_helper/get_schema";
export const GET_COLUMN_DESCRIPTION = "/productivity_helper/get_column_description";
export const UPDATE_TABLE_DESCRIPTION = "/productivity_helper/update_table_description";
export const GET_PROMPT_DATA = "/productivity_helper/prompt_template";
export const GET_QUERY = "/productivity_helper/query";
export const UPDATE_TABLE_COLUMN_DESCRIPTION = "/productivity_helper/update_column_description";
export const GOTO_LAST_PROMPT = "/productivity_helper/get_last_config";

// Python Prompt API
export const GET_PYTHON_PROMPT = "/productivity_helper/script";

// QA Test Cases API
export const GET_JIRA_PROJECTS = "/productivity_helper/get_jira_projects";
export const GET_EPICS = "/productivity_helper/get_jira_epics";
export const GET_STORIES = "/productivity_helper/get_jira_stories";
export const GET_STORY_DESCRIPTION = "/productivity_helper/get_stories_description";
export const GET_QA_PROMPT = "/productivity_helper/qa_prompt_template";
export const GENERATE_TEST_CASE = "/productivity_helper/qa_test_cases";

export const GET_EXCEL_DATA = "/productivity_helper/download";
export const GET_TASKS = "/productivity_helper/get_jira_tasks";

// Configurator apis
export const SIDE_PANEL_DATA = `/core/configuration/sidelayout`;
export const FETCH_APPS_CONFIGURATOR_API = "/core/configurator/app-config";

//JSON Parser api
export const GET_JSON_STRUCTURE = `/core/configuration/template`;

