// MODULE LIST

export const CONFIGURATION_MODULE = "configuration";
export const APPLICATION_ACCESS_MANAGEMENT_MODULE = "application_access_management";
export const SOURCING_MAPPING_MODULE = "sourcing";
export const DERIVED_TABLE_MAPPING_MODULE = "derived_table_mapping";
export const GENERIC_SCHEMA_MAPPING_MODULE = "generic_schema_mapping";
export const SOURCING_CONFIGURATION_MODULE = "sourcing_configuration";
export const DATA_SOURCE_MODULE = "data_source";
export const GENERIC_MASTER_MAPPING_MODULE = "generic_master_mapping";
export const INGESTION_CONFIGURATION_MODULE = "ingestion_configuration";
export const GENERIC_TRIGGERS_MAPPING_MODULE = "generic_trigger_mapping";
export const QC_KPI_MASTER_MODULE = "kpi_master";
export const QC_RULE_MASTER_MODULE = "rule_master";
export const QC_CUSTOM_QUERY_MODULE = "Custom Query";
export const QC_RULE_MASTER = "Rule Engine";
export const QC_CUSTOM_QUERY_MODULE_NAME = "custom_qc";

// ROLES
export const ADMIN = "admin";
export const VIEWER = "viewer";
export const VIEW = "view";
export const EDIT = "edit";
export const CREATE = "create";
export const DELETE = "delete";
export const APPROVE = "approve";
export const DEVELOPER = "developer";

export const DATA_SOURCES = "Data Sources";
export const SOURCING_CONFIGURATION = "Sourcing Configuration";
export const MAPPING_TABLE = "Mapping Table"
export const TRIGGERS = "Triggers"

export const INGESTION_CONFIGURATION = "Ingestion Configuration";
export const TABLE_LIST = "Generic Mapping Table List"
export const GENERIC_SCHEMA_MAPPINGS = "Generic Schema Mappings";
export const QC_MODULE = "QC module";

export const DERIVED_TABLES = "Derived Tables";