import { ENV, TENANT } from "config/api";
import { TENANT_MAPPING } from "config/constants";

let authConfig = {};

if (ENV === "devs" || ENV === "test" || TENANT.includes("localhost")) {
  //Dev and TEST environments are included here
  if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.VS) {
    authConfig = {
      apiKey: "AIzaSyAHKSk52_L_cMbAT0p31Cck6ed_7CsD8hw",
      authDomain: "victorias-secret-393308.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (
    [
      TENANT_MAPPING.SIGNET,
      TENANT_MAPPING.VB,
      TENANT_MAPPING.PARTY_CITY,
      TENANT_MAPPING.PUMA,
    ].includes(TENANT.toLocaleLowerCase())
  ) {
    authConfig = {
      apiKey: "AIzaSyDAZfJzJ57rMOyAgmLCgSc3L1NkpnKdFVY",
      authDomain: "impactsmart.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (
    [TENANT_MAPPING.PRODUCTIVITY_HELPER, TENANT_MAPPING.GENERIC].includes(
      TENANT.toLocaleLowerCase()
    )
  ) {
    authConfig = {
      apiKey: "AIzaSyCLzIDuxkqWZV2BvqUKSQU2MP9pdHkd06c",
      authDomain: "platform-internal.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.ARHAUS) {
    authConfig = {
      apiKey: "AIzaSyBcVTV1dvlmWyTeP1QlCMCw73Q_EuLnjy8",
      authDomain: "arhaus-401512.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else {
    authConfig = {
      apiKey: "AIzaSyDAZfJzJ57rMOyAgmLCgSc3L1NkpnKdFVY",
      authDomain: "impactsmart.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  }
} else {
  if (TENANT.toLocaleLowerCase() === TENANT_MAPPING.VS) {
    authConfig = {
      apiKey: "AIzaSyAHKSk52_L_cMbAT0p31Cck6ed_7CsD8hw",
      authDomain: "victorias-secret-393308.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else if (
    [
      TENANT_MAPPING.SIGNET,
      TENANT_MAPPING.VB,
      TENANT_MAPPING.PARTY_CITY,
      TENANT_MAPPING.PUMA,
    ].includes(TENANT.toLocaleLowerCase())
  ) {
    authConfig = {
      apiKey: "AIzaSyCo4HZ-TWBP8U36B8d_klhy3ZPg0Jc5D3k",
      authDomain: "impactsmart-prod.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  } else if (
    [TENANT_MAPPING.PRODUCTIVITY_HELPER, TENANT_MAPPING.GENERIC].includes(
      TENANT.toLocaleLowerCase()
    )
  ) {
    authConfig = {
      apiKey: "AIzaSyCLzIDuxkqWZV2BvqUKSQU2MP9pdHkd06c",
      authDomain: "platform-internal.firebaseapp.com",
      // whatFixUrl:"https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js"
    };
  } else {
    authConfig = {
      apiKey: "AIzaSyCo4HZ-TWBP8U36B8d_klhy3ZPg0Jc5D3k",
      authDomain: "impactsmart-prod.firebaseapp.com",
      whatFixUrl:
        "https://whatfix.com/7aed52c2-cea5-451e-8310-ab7609db8800/embed/embed.nocache.js",
    };
  }
}

export default authConfig;
