import { GET_DATA_SOURCES, SET_DATA_INGESTION_WSCLIENT, SET_DATA_SOURCES, SET_INGESTION_DATA, SET_MODULE_INFO, UPDATE_SUBMODULE_STATUS } from "../actions/types";

export const initialState = {
  dataSourcesDetails: [],
  ingestionData: [],
  wsClient: null,
  subModuleStatus: true,
  moduleInfo:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATA_SOURCES:
      return {
        ...state,
        dataSourcesDetails: action.payload,
      };
    case GET_DATA_SOURCES:
      return {
        ...state,
      };
    case SET_DATA_INGESTION_WSCLIENT:
      return {
        ...state,
        wsClient: action.payload.wsClient,
      };
    case SET_INGESTION_DATA:
      return {
        ...state,
        ingestionData: [...action.payload.results],
      };
    case UPDATE_SUBMODULE_STATUS:
      return {
        ...state,
        subModuleStatus : action.payload.status,
      };
      case SET_MODULE_INFO:
        return {
          ...state,
          moduleInfo: [...action.payload.results],
        };
    default:
      return state;
  }
}
