import { GET_MODULE_LEVEL_ACCESS } from "config/api";
import axiosInstance from "Utils/axios";
import axiosPlatformInstance from "Utils/axiosPlatform";
import {
  DATAPLATFORM_DATA_SOURCE,
  DATAPLATFORM_DATA_SOURCE_MAPPING,
  DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_CONFIG_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST,
  DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT,
  DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT,
  DATAPLATFORM_GET_SAMPLE_DATA,
  DATAPLATFORM_GENERIC_SCHEMA_MAPPING,
  DATAPLATFORM_TEST_CONNECTOR_CONFIG,
  DATA_PLATFORM_DAG_IDS,
  DATA_PLATFORM_DAG_INFO,
  DATA_PLATFORM_TIME_TRACKER_DAGS_LIST,
  DATA_PLATFORM_COST_DAG_IDS,
  DATA_PLATFORM_DAG_COST_INFO,
  DATA_PLATFORM_MODULES_CONFIG,
  DATA_PLATFORM_PAUSE_PIPELINE,
  DATA_PLATFORM_RESUME_PIPELINE,
  DATA_PLATFORM_CLEAR_TASK,
  DATA_PLATFORM_START_PIPELINE,
  DATA_PLATFORM_UPDATE_SUBMODULE_STATUS,
  DATA_PLATFORM_SAMPLE_INGESTION_DATA,
  DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING
} from "../constants-dataIngestion/apiConstants";

//module access
export const getModuleLevelAccess = (app, module) => () => {
  const queryParam = {
    app,
    module,
  };
  return axiosInstance({
    url: `${GET_MODULE_LEVEL_ACCESS}`,
    params: queryParam,
    method: "GET",
  });
};

// Data Transfer
export const saveDataTransferFormDetails = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getDataTransferFormDynamicFields = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getDataTransferFormJobConfigDynamicFields = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_JOB_CONFIG_DYNAMIC_FIELDS}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getTotalJobCount = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT}`,
    method: "GET"
  });
  return data;
};

export const getJobList = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST}`,
    method: "POST",
    data: reqBody,
  });
  return data;
}


//data-sources connector
export const getAllConnectors = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}`,
    method: "GET",
  });
  return data;
}

export const testConnector = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_TEST_CONNECTOR_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const addConnectorTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getConnectorTableConfigById = (
  Id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "GET"
  });
  return data;
};

export const updateConnectorById = (
  Id, reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};
export const deleteConnectorConfigById = (Id, postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "DELETE",
    data: postBody
  });
};

//sourcing mapping
export const createMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE_MAPPING}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: DATAPLATFORM_DATA_SOURCE_MAPPING,
    method: "DELETE",
    data: postBody
  });
};

//sample data view
export const getSampleDataView = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GET_SAMPLE_DATA}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

//generic master mapping table
export const createGenericMasterMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteMasterMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "DELETE",
    data: postBody
  });
};

export const editMasterMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "PUT",
    data: postBody
  });
};

//generic schema mapping
export const createGenericSchemaMappingTableConfig = (
  reqBody, table_name
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteSchemaMappingConfig = (postBody, table_name) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "DELETE",
    data: postBody
  });
};

export const editSchemaMappingConfig = (postBody, table_name) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "PUT",
    data: postBody
  });
};

//reports

export const getDagsList = (
  module
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_TIME_TRACKER_DAGS_LIST}/${module}`,
    method: "GET"
  });
  return data;
};

export const getDagIds = (
  start, end
) => async () => {
  let URL = !(start && end) ? `${DATA_PLATFORM_DAG_IDS}` : `${DATA_PLATFORM_DAG_IDS}?start_date_gte=${start}&start_date_lte=${end}`;
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "GET"
  });
  return data;
};

export const getDagTaskInfo = (
  module, dag_run_id, dag_id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_DAG_INFO}/${module}/${dag_id}/${dag_run_id}`,
    method: "GET"
  });
  return data;
};

export const getDagCostIds = (
  start, end
) => async () => {
  let URL = !(start && end) ? `${DATA_PLATFORM_COST_DAG_IDS}` : `${DATA_PLATFORM_COST_DAG_IDS}?start_date=${start}&end_date=${end}`;
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "GET",
  });
  return data;
};

export const getModulesInfo = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_MODULES_CONFIG}`,
    method: "GET",
  });
  return data;
};

export const getSampleIngestionCurrentData = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_SAMPLE_INGESTION_DATA}`,
    method: "GET",
  });
  return data;
};

export const getDagTaskCostInfo = (
  module, dag_run_id, dag_id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_DAG_COST_INFO}/${module}/${dag_id}/${dag_run_id}`,
    method: "GET"
  });
  return data;
};
export const pausePipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_PAUSE_PIPELINE}`,
    method: "GET",
  });
  return data;
};

export const resumePipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_RESUME_PIPELINE}`,
    method: "GET",
  });
  return data;
};

export const clearTask = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_CLEAR_TASK}`,
    method: "GET",
  });
  return data;
};

export const startPipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_START_PIPELINE}`,
    method: "POST",
    data: {
      "ingestion_type": "non-periodic"
    }
  });
  return data;
};

export const updateSubmoduleStatus = (id) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_UPDATE_SUBMODULE_STATUS}/${id}`,
    method: "GET",
  });
  return data;
};
