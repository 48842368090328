import React, { useEffect, useState } from "react";
// import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import GenericApplicationConfiguration from "./components/data-ingestion-configuration";
import { connect } from "react-redux";
import { homePageActions } from "actions/homePageActions";
import { getApplicationMaster } from "../../../../../actions/tenantConfigActions";
import Configuration from "../../components/Sourcingconfiguration";
import { DATA_SOURCES } from "modules/dataIngestion/constants-dataIngestion/moduleConstants";


const GenericAppConfig = (props) => {
  const globalClasses = globalStyles();
  const [configurationTab, setConfigurationTab] = useState(
    DATA_SOURCES
  );
  const [tabList, setTabList] = useState([]);
  const [tenantTabConfig, setTenantTabConfig] = useState([]);
  const [applicationMasterData, setApplicationMasterData] = useState("");

  useEffect(async () => {
    let getApplicationMasterData = await props.getApplicationMaster();
    if (getApplicationMasterData?.data?.status) {
      setApplicationMasterData(getApplicationMasterData?.data?.data);
    }
  }, []);

  useEffect(() => {
    if (applicationMasterData?.length) {
      setTenantTabConfig([
        {
          pageHeader: "PlanSmart WorkFlow ",
          appType: "PlanSmart",
          tabName: "PlanSmart Workflow",
          applicationCode: applicationMasterData.find((appData) => {
            if (appData.name === "PlanSmart") {
              return appData.application_code;
            }
          })?.application_code,
        },
        {
          pageHeader: "AssortSmart WorkFlow ",
          appType: "AssortSmart",
          tabName: "AssortSmart Workflow",
          applicationCode: applicationMasterData.find((appData) => {
            if (appData.name === "AssortSmart") {
              return appData.application_code;
            }
          })?.application_code,
        },
        {
          pageHeader: "InventorySmart WorkFlow ",
          appType: "InventorySmart",
          tabName: "InventorySmart Workflow",
          applicationCode: applicationMasterData.find((appData) => {
            if (appData.name === "InventorySmart") {
              return appData.application_code;
            }
          })?.application_code,
        },
      ]); //To do: Make this call fully dynamic
    }
  }, [applicationMasterData]);

  useEffect(() => {
    let lowerCaseAppList = props.enabledApplications.map((app) =>
      app.toLowerCase()
    );
    if (tenantTabConfig?.length) {
      let filteredApplication = tenantTabConfig.filter((app) => {
        return lowerCaseAppList.includes(app.appType.toLowerCase());
      });
      setTabList(filteredApplication);
      //To do: Make this call fully dynamic
    }
  }, [tenantTabConfig]);

  const handleConfiguration = (event, newSelection) => {
    if (newSelection !== null) {
      setConfigurationTab(newSelection);
    }
  };

  return (
    <div style={{display:"contents"}}>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.centerAlign} ${globalClasses.marginTop} ${globalClasses.marginBottom}`}
      >
        {/* <ToggleButtonGroup
          value={configurationTab}
          exclusive
          onChange={handleConfiguration}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton value={DATA_SOURCES} aria-label="left aligned">
          {props.location.pathname !== "/data-platform/configuration" ? DATA_SOURCES :"Data Ingestion Configuration"}
          </ToggleButton>

          {props.location.pathname !== "/data-platform/configuration" && [MAPPING_TABLE, SOURCING_CONFIGURATION].map((config) => {
            return (
              <ToggleButton value={config} aria-label="centered">
                {config}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup> */}
      </div>

      <Configuration 
        configurationTab = {props.label}
        // location = {props.location.pathname}
        />
      {/* <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item sx={4}>
              <Button
                variant="contained"
                color="primary"
                className={globalClasses.marginAround}
                onClick={() => {
                  props.onSaveHandler();
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item sx={4}>
              <Button variant="outlined" className={globalClasses.marginAround}>
                Cancel
              </Button>
            </Grid>
          </Grid> */}

      {tabList.map((config, index) => {
        return (
          <>
            {configurationTab === `${config.tabName}` && (
              <GenericApplicationConfiguration
                appInfo={tabList[index]}
              ></GenericApplicationConfiguration>
            )}
          </>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    enabledApplications: state.homePageReducer.userAppData,
  };
};

const mapActionsToProps = {
  setUserApps: homePageActions,
  getApplicationMaster,
};

export default connect(mapStateToProps, mapActionsToProps)(GenericAppConfig);
