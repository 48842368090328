import axiosInstance from "../Utils/axios";
import {
  TENANT_APP_CONFIG,
  HIERARCHIES_LIST,
  GET_APP_CONFIG,
  GET_KPI_CONFIG,
  GET_EXITING_PLANNING_LEVEL,
  GET_TENANT_FILTER_CONFIG,
  POST_TENANT_FILTER_CONFIG,
  GET_APPLICATION_MASTER,
  UPDATE_TENANT_ATTRIBUTE_CONFIG,
  GET_TENANT_CONFIG,
  PUT_DATA_INGESTION_CONFIG,
  PUT_MAPPING_TABLE_CONFIG,
  PUT_DATA_INGESTION_SOURCING_CONFIG,
  PUT_DERIVED_TABLE_CONFIG,
  PUT_TRIGGERS_TABLE_CONFIG,
  PUT_QC_KPI_MODULE_TABLE_CONFIG,
  PUT_QC_RULE_MODULE_TABLE_CONFIG,
  PUT_QC_CUSTOM_QUERY_UPDATE_TABLE_CONFIG,
  REMOVE_QC_CUSTOM_QUERY_TABLE_CONFIG,
  QC_ADD_TABLE_CONFIG,
  QC_ADD_CUSTOM_QUERY_MODULE_TABLE_CONFIG
} from "../config/api";
import {
  SET_HIERARCHY_LEVEL,
  SET_HELP_DESK,
  SET_SPECIFIC_SCREEN_NAME,
} from "./types";
import axiosPlatformInstance from "Utils/axiosPlatform";
// import { GET_STORE_ATTRIBUTES } from "modules/plansmart/constants-plansmart/apiConstants";
// import { GET_DASHBOARD_FILTERS } from "modules/assortsmart/constants-assortsmart/apiConstants";

export const tenantAppConfig = () => async () => {
  const { data } = await axiosInstance({
    url: `${TENANT_APP_CONFIG}`,
    method: "GET",
  });
  return data.data;
};

export const hierarchiesList = () => async () => {
  const { data } = await axiosInstance({
    url: `${HIERARCHIES_LIST}`,
    method: "GET",
  });
  return data.data;
};

export const getAppConfig = (queryStr = "") => async () => {
  const { data } = await axiosInstance({
    url: GET_APP_CONFIG + queryStr,
    method: "GET",
  });
  return data.data;
};
export const setAppConfig = (appConfig) => async (dispatch) => {
  dispatch({
    type: SET_HIERARCHY_LEVEL,
    payload: appConfig,
  });
};
export const updateAppConfig = (reqBody, queryStr = "") => async () => {
  await axiosInstance({
    url: GET_APP_CONFIG + queryStr,
    method: "PUT",
    data: reqBody,
  });
};
export const getKpiConfig = (reqBody) => async () => {
  const { data } = await axiosInstance({
    url: GET_KPI_CONFIG,
    method: "POST",
    data: reqBody,
  });
  return data.data;
};
export const updateKpiConfig = (reqBody) => async () => {
  const { data } = await axiosInstance({
    url: GET_KPI_CONFIG,
    method: "PUT",
    data: reqBody,
  });
  return data.data;
};
export const getExistingPlanningLevel = (reqBody) => async () => {
  const { data } = await axiosInstance({
    url: GET_EXITING_PLANNING_LEVEL,
    method: "POST",
    data: reqBody,
  });
  return data.data;
};
export const updatePlanningLevel = (level, reqBody) => async () => {
  await axiosInstance({
    url: `${GET_EXITING_PLANNING_LEVEL}?level=${level}`,
    method: "PUT",
    data: reqBody,
  });
};
export const getTenantFilterConfig = (applicationCode) => async () => {
  const { data } = await axiosInstance({
    url: `${GET_TENANT_FILTER_CONFIG}/${applicationCode}`,
    method: "GET",
  });
  return data;
};

export const saveTenantFilterConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosInstance({
    url: `${POST_TENANT_FILTER_CONFIG}/${applicationCode}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const saveIngestionSourcingConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_DATA_INGESTION_SOURCING_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const saveIngestionConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_DATA_INGESTION_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const saveMappingTableConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_MAPPING_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const createMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_MAPPING_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const createKpiMasterRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_KPI_MODULE_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const updateKpiMasterRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_KPI_MODULE_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const createRuleMasterRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_RULE_MODULE_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const updateRuleMasterRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_RULE_MODULE_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const deleteRuleMasterRow = (
  rule
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_RULE_MODULE_TABLE_CONFIG}?rule=${rule}`,
    method: "DELETE",
  });
  return data;
};

export const addNewTable = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${QC_ADD_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const addNewCustomQueryRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${QC_ADD_CUSTOM_QUERY_MODULE_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const updateCustomQueryRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_QC_CUSTOM_QUERY_UPDATE_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const deleteCustomQueryRow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${REMOVE_QC_CUSTOM_QUERY_TABLE_CONFIG}`,
    method: "DELETE",
    data: reqBody
  });
  return data;
};

export const deleteKpiMasterRow = (
  kpi_code,
  consent
) => async () => {
  let URL = consent ? `${PUT_QC_KPI_MODULE_TABLE_CONFIG}?kpicode=${kpi_code}&consent=${consent}` : `${PUT_QC_KPI_MODULE_TABLE_CONFIG}?kpicode=${kpi_code}`;
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "DELETE",
  });
  return data;
};

export const saveTriggersTableConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_TRIGGERS_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const createTriggersTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_TRIGGERS_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteTriggersTableConfig = (
  view
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_TRIGGERS_TABLE_CONFIG}/${view}`,
    method: "DELETE"
  });
  return data;
};

export const createDerviedMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_DERIVED_TABLE_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const saveDerivedTableConfig = (
  applicationCode,
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${PUT_DERIVED_TABLE_CONFIG}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};

export const getFilterConfiguration = () => () => {
  return axiosInstance({
    url: "/assort/plan/dashboard-filters",
    method: "GET",
  });
};

export const getDropdownValues = (
  applicationCode = 1,
  queryParam = {}
) => async () => {
  return axiosInstance({
    url: `${GET_TENANT_CONFIG}/${applicationCode}`,
    params: queryParam,
    method: "GET",
  });
};

export const getStoreDropdownValues = (postBody) => async () => {
  return axiosInstance({
    url: "/plan-smart/plan/store-attributes",
    method: "POST",
    data: postBody,
  });
};

export const getApplicationMaster = (postBody) => async () => {
  // console.log(postBody)
  // return axiosInstance({
  //   url: GET_APPLICATION_MASTER,
  //   method: "GET",
  //   data: postBody,
  // });
};

export const updateTenantAttributeConfig = (
  attributeLevel,
  reqBody
) => async () => {
  const { data } = await axiosInstance({
    url: `${UPDATE_TENANT_ATTRIBUTE_CONFIG}/${attributeLevel}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const setHelpDesk = (data) => async (dispatch) => {
  dispatch({
    type: SET_HELP_DESK,
    payload: data,
  });
};

export const getSpecificScreenName = (
  applicationCode = 1,
  queryParam = {}
) => async (dispatch) => {
  const { data } = await axiosInstance({
    url: `${GET_TENANT_CONFIG}/${applicationCode}`,
    params: queryParam,
    method: "GET",
  });
  dispatch({
    type: SET_SPECIFIC_SCREEN_NAME,
    payload: data?.data?.[0],
  });
};

//queryParam -> attribute name
//ex: -> /tenant-config/1?attribute_name=default_store_groups

export const getTenantConfigApplicationLevel = (
  applicationCode = 1,
  queryParam = {}
) => () => {
  return axiosInstance({
    url: `${GET_TENANT_CONFIG}/${applicationCode}`,
    params: queryParam,
    method: "GET",
  });
};

export const getTenantTimeConfig = async (applicationCode = 3) => {
  // let { data } = await axiosInstance({
  //   url: `${GET_TENANT_CONFIG}/${applicationCode}${"?attribute_name=tenant_time_config"}`,
  //   method: "GET",
  // }
  // );

  // return data.data;
};
export const getFilterExclusionValues = async (applicationCode = 1) => {
  let { data } = await axiosInstance({
    url: `${GET_TENANT_CONFIG}/${applicationCode}?${"attribute_name=filter_attribute_exclusion_values"}`,
    method: "GET",
  });
  return data.data;
};
